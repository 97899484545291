import React from 'react'
import { graphql } from 'gatsby'

// Layout
import Seo from '../components/layout/Seo/Seo'

// Views
import BlogArticleSearch from '../components/views/BlogArticleSearch/BlogArticleSearch'

const BlogArticleSearchTemplate = ({ data, pageContext, location }) => {
  return (
    <>
      <Seo
        title={data.page.data.title.text}
        description='The latest news and insights on coding and the tech industry'
        slug={location.pathname}
      />
      <BlogArticleSearch
        data={data}
        location={location}
      />
    </>
  )
}

export default BlogArticleSearchTemplate

export const PageQuery = graphql`
  query BlogArticleSearchTemplateQuery {
    page: prismicPage (
      uid: {
        eq: "blog"
      }
    ) {
      uid
      data {
        title {
          text
        }
      }
      ...PreFooter
    }
    blogArticles: allPrismicBlogArticle (
      sort: {
        order: DESC,
        fields: data___date
      }
    ) {
      nodes {
        id
        ...BlogArticle
      }
    }
  }
`
