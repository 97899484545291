import React from 'react'
import PropTypes from 'prop-types'

// Generic
import Container from '../../shared/Container/Container'
import SearchForm from '../SearchForm/SearchForm'

// Utils
import { urlMap } from '../../../utils/urls'

const BlogArticleIndexHero = (props) => {
  const { handleSubmit, handleSearchInputChange, inputElement, title, body, formIsLoading } = props

  return (
    <section className='pt-32 pb-20 sm:pt-48 sm:pb-32 bg-offwhite'>
      <Container>
        <h1 className='mb-4'>{title}</h1>
        <p className='text-2xl mb-8'>{body}</p>
        <SearchForm
          action={`${urlMap.blog_article}search`}
          handleSubmit={handleSubmit}
          handleSearchInputChange={handleSearchInputChange}
          inputElement={inputElement}
          inputName='q'
          isLoading={formIsLoading}
        />
      </Container>
    </section>
  )
}

BlogArticleIndexHero.propTypes = {
  handleSubmit: PropTypes.func,
  handleSearchInputChange: PropTypes.func,
  inputElement: PropTypes.object,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  formIsLoading: PropTypes.bool
}

export default BlogArticleIndexHero
