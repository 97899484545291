import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Generic
import Button from '../Button/Button'

// Icons
import IconSearch from '../../../assets/img/icon-search.svg'
import IconLoading from '../../../assets/img/icon-loading.svg'

const SearchForm = (props) => {
  const { action, handleSubmit, handleSearchInputChange, inputElement, inputName, isLoading } = props

  return (
    <form
      action={action}
      onSubmit={handleSubmit}
      className={classNames({ 'pointer-events-none': isLoading })}
    >
      <div className='inline-flex justify-center relative bg-white shadow-lg p-3'>
        <IconSearch className='w-6 absolute left-0 top-1/2 transform -translate-y-1/2 translate-x-6' />
        <input
          type='search'
          name={inputName || 'query'}
          placeholder='Search for something...'
          className='w-full sm:w-80 h-16 pl-12 pr-4 appearance-none border border-white focus:rounded-none focus:outline-none'
          onChange={handleSearchInputChange}
          ref={inputElement}
          required
        />
        <Button
          element='button'
          type='submit'
          color='primary'
          icon
        >
          Search
        </Button>
        {isLoading && (
          <div className='flex justify-center items-center absolute inset-0'><IconLoading className='animate-spin h-5 w-5 text-midnight' /></div>
        )}
      </div>
    </form>
  )
}

SearchForm.propTypes = {
  action: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleSearchInputChange: PropTypes.func,
  inputElement: PropTypes.object,
  inputName: PropTypes.string,
  isLoading: PropTypes.bool
}

export default SearchForm
