import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import * as JsSearch from 'js-search'
import jump from 'jump.js'

// Content Components
import PageElements from '../../elements/PageElements'

// Generic
import ArticleGrid from '../../shared/ArticleGrid/ArticleGrid'

// Local
import BlogArticleIndexHero from '../../shared/BlogArticle/BlogArticleIndexHero'
import BlogArticleIndexContent from '../../shared/BlogArticle/BlogArticleIndexContent'
import BlogArticleIndexContentHeader from '../../shared/BlogArticle/BlogArticleIndexContentHeader'

// Utils
import blogArticleDecorator from '../../../utils/blogArticleDecorator'

const BlogArticleSearch = ({ data, location }) => {
  // We don't want to show ALL blog posts, so only start searching when there
  // are 3 or more characters
  const minQueryLength = 3

  // Reformat the data so relevant items are top-level
  const allBlogArticles = data.blogArticles.nodes.map((blogArticle) => {
    return {
      title: blogArticle.data.title.text,
      intro: blogArticle.data.intro.text,
      ...blogArticle
    }
  })

  const inputElement = useRef(null)

  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])

  /**
   * Setup JsSearch with relevant options
   */
  const searchInstance = new JsSearch.Search('id')
  /**
   * defines an indexing strategy for the data
   * read more about it here https://github.com/bvaughn/js-search#configuring-the-index-strategy
   */
  searchInstance.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()

  /**
   * defines the sanitizer for the search
   * to prevent some of the words from being excluded
   */
  searchInstance.sanitizer = new JsSearch.LowerCaseSanitizer()

  searchInstance.searchIndex = new JsSearch.TfIdfSearchIndex('id')

  // sets the index attribute for the data
  searchInstance.addIndex('title')
  searchInstance.addIndex('intro')

  // adds the data to be searched
  searchInstance.addDocuments(allBlogArticles)

  // Get the query from the search state passed from the previous page
  useEffect(() => {
    if (location.state.searchQuery) {
      setSearchQuery(location.state.searchQuery)
      inputElement.current.value = location.state.searchQuery
    }
    filterResults(location.state.searchQuery)
  }, [])

  const filterResults = (query) => {
    const results = searchInstance.search(query)
    setSearchResults(results)
  }

  /**
   * Get the search query and filter the results
   * @param  {[type]} event [description]
   * @return {[type]}       [description]
   */
  const handleSearchInputChange = (event) => {
    if (!event.target.value || event.target.value.trim().length < minQueryLength) {
      setSearchResults([])
    } else {
      filterResults(event.target.value.trim())
    }

    setSearchQuery(event.target.value.trim())
  }

  /**
   * Just move down the page when the form is submitted, the actual searching
   * is done when the search input changes
   * @param  {Object} event
   * @return {Void}
   */
  const handleSubmit = (event) => {
    event.preventDefault()

    jump('#content')
  }

  return (
    <>
      <BlogArticleIndexHero
        title={data.page.data.title.text}
        body='The latest news and insights on coding and the tech industry'
        handleSubmit={handleSubmit}
        handleSearchInputChange={handleSearchInputChange}
        inputElement={inputElement}
      />
      <BlogArticleIndexContent>
        <BlogArticleIndexContentHeader>
          <h2>Search Results</h2>
        </BlogArticleIndexContentHeader>
        <div className='mb-16'>
          {(searchResults.length > 0) ? (
            <ArticleGrid
              articles={blogArticleDecorator(searchResults)}
            />
          ) : (
            <p>
              {searchQuery.length < minQueryLength ? 'Please type a longer query, or ' : 'No results found. Type something else, or '}
              <Link
                to='/company/blog'
                className='text-primary'
              >
                go back to the Northcoders Blog.
              </Link>
            </p>
          )}
        </div>
      </BlogArticleIndexContent>
      <PageElements slices={data.page.data.body} />
    </>
  )
}

BlogArticleSearch.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default BlogArticleSearch
