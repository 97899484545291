/**
 * Config for the urls where each content type should resolve to.
 * @type {Object}
 */
const urlMap = {
  blog_article: '/company/blog/',
  blog_category: '/company/blog/category/',
  career: '/company/careers/',
  case_study: '/resources/testimonials/businesses/',
  course: '/our-courses/',
  faqs_page: '/',
  page: '/',
  press_release: '/press-and-media/',
  press_release_category: '/press-and-media/category/',
  testimonial: '/resources/testimonials/'
}

/**
 * Get the directory based on the document tags set in the CMS.
 * Directory tags are written in `directory:xyz` format, where `xyz` is the
 * value we want to use.
 * @param  {Object} doc
 * @return {String}
 */
const urlDirectory = (doc, directory) => {
  const tagDirectory = doc.tags.find(tag => tag.includes('directory:'))
  if (tagDirectory) {
    return `${tagDirectory.split(':')[1]}/`
  }

  return directory ? `${directory}/` : ''
}

module.exports = { urlMap, urlDirectory }
