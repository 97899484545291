import React from 'react'

const BlogArticleIndexContentHeader = (props) => {
  const { children } = props

  return (
    <div className='sm:flex justify-between items-center mb-10 sm:mb-20 space-y-10 sm:space-y-0'>
      {children}
    </div>
  )
}

export default BlogArticleIndexContentHeader
