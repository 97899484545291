import React from 'react'

// Generic
import Container from '../../shared/Container/Container'

const BlogArticleIndexContent = (props) => {
  const { children } = props
  return (
    <>
      <section className='pt-10 sm:pt-20' id='content'>
        <Container>
          {children}
        </Container>
      </section>
    </>
  )
}

export default BlogArticleIndexContent
